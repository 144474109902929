import { Routes, Route, Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Login from './Components/Authentication/Login';
import Hello from './Components/Landing';
import ForgotPassword from './Components/Authentication/ForgotPassword';
import OnBoarding from './Components/Onboarding';
import Dashboard from './Components/Dashboard';
import FullScan from './Components/Scan/fullScan';
import QuickScan from './Components/Scan/quickScan';
import Landscape from './Components/Landscape';
import Reports from './Components/Reports';
import Profile from './Components/Profile';
import ChangePassword from './Components/Authentication/ChangePassword';
import PrivateRoute from './Components/ProtectedRoutes/private';
import PublicRoute from './Components/ProtectedRoutes/public';
import SignIn from './Components/Authentication/SignIn';
import './App.css';
import Otp from './Components/Authentication/Otp';
import NewPassword from './Components/Authentication/NewPassword';
import PageNotFound from './Components/PageNotFound';
import Menu from './Components/Dashboard/menu';
import Gallery from './Components/Scan/gallery';
import TpScanLanding from './Components/Landing/tpscanLanding'
import { tpscanName } from './Utils/AppDetails/appDetails';
import { getDesktopOrMobile } from './Utils/deviceDetails';
import QrCode from './Components/QrCode';
import Validate from './Components/ProtectedRoutes/dentalOffice';
import ValidateApp from './Components/ProtectedRoutes/appDetails';
import PrivacyPolicy from './Components/PrivacyPolicy';
import Feedback from './Components/Feedback';
import TpScanAutoLandingUid from './Components/Landing/tpScanLandingAutoUid';
const App = () => {

  const [isDesktop, setDeviceType] = useState(false)
  const [isLandscape, setLandscape] = useState(window.innerWidth > window.innerHeight)
  const handleOrientation = () => {
    detectDevice()
    setLandscape(window.innerWidth > window.innerHeight)
    document.documentElement.style.setProperty("--browser-offset", `calc(var(--vh, 1vh) * 100 - ${window.innerHeight}px)`);
  }

  const detectDevice = () => {
    const isDesktopWindows = getDesktopOrMobile()
    setDeviceType(isDesktopWindows)
  }

  useEffect(() => {
    window.addEventListener("resize", handleOrientation)
    handleOrientation()
    // window.open()
    return () => {
      window.removeEventListener("resize", handleOrientation)
    }
  }, [])


  return (
    <div className="App">
      {
        isDesktop ?
          <Routes>
            <Route path="/" element={<QrCode />} />
            <Route path="/:dentalOfficeName" element={<QrCode />} />
            <Route path="*" element={<QrCode />} />
          </Routes> :
          isLandscape ? <Landscape /> :
            <Routes>
              <Route exact path="/" element={<Navigate to={`/Toothlens-Inc`} />} />
              <Route exact path="/tpscan/toothlens/:uid" element={<PublicRoute Component={<TpScanLanding />} />} />
              <Route exact path="/tpscan/banningdental/:uid" element={<PublicRoute Component={<TpScanLanding />} />} />
              <Route exact path="/tpscan/pg/:uid" element={<PublicRoute Component={<TpScanLanding />} />} />
              <Route exact path="/tpscan/ybrush/:uid" element={<PublicRoute Component={<TpScanLanding />} />} />
              <Route exact path="/tpscan/done/:uid" element={<PublicRoute Component={<TpScanLanding />} />} />
              <Route path={`/${tpscanName}/full-scan`} element={<FullScan />} />
              <Route path={`/${tpscanName}/onboarding`} element={<OnBoarding />} />
              <Route exact path="/tpscan/banningdental" element={<PublicRoute Component={<TpScanAutoLandingUid />} />} />
              <Route exact path="/:dentalOfficeName" element={<Validate Component={<PublicRoute Component={<Hello />} />} />} />
              <Route path="/:dentalOfficeName/onboarding" element={<OnBoarding />} />
              <Route path="/:dentalOfficeName/full-scan" element={<FullScan />} />
              <Route path="/:dentalOfficeName/login" element={<ValidateApp Component={<PublicRoute Component={<Login />} />} />} />
              <Route path="/:dentalOfficeName/sign-in" element={<ValidateApp Component={<PublicRoute Component={<SignIn />} />} />} />
              <Route path="/:dentalOfficeName/dashboard" element={<Validate Component={<PrivateRoute Component={<Dashboard />} />} />} />
              <Route path="/:dentalOfficeName/quick-scan" element={<Validate Component={<PrivateRoute Component={<QuickScan />} />} />} />
              <Route path="/:dentalOfficeName/reports" element={<Validate Component={<PrivateRoute Component={<Reports />} />} />} />
              <Route path="/:dentalOfficeName/profile" element={<Validate Component={<PrivateRoute Component={<Profile />} />} />} />
              <Route path="/:dentalOfficeName/privacypolicy" element={<Validate Component={<PrivateRoute Component={<PrivacyPolicy />} />} />} />
              <Route path="/:dentalOfficeName/feedback" element={<Validate Component={<PrivateRoute Component={<Feedback />} />} />} />
              <Route path="/:dentalOfficeName/change-password" element={<Validate Component={<PrivateRoute Component={<ChangePassword />} />} />} />
              <Route path="/:dentalOfficeName/menu" element={<Validate Component={<PrivateRoute Component={<Menu />} />} />} />
              <Route path="/:dentalOfficeName/gallery" element={<Validate Component={<PrivateRoute Component={<Gallery />} />} />} />
              <Route path="/:dentalOfficeName/forgot-password" element={<Validate Component={<ForgotPassword />} />} />
              <Route path="/:dentalOfficeName/otp" element={<Validate Component={<Otp />} />} />
              <Route path="/:dentalOfficeName/reset-password" element={<Validate Component={<NewPassword />} />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
      }
    </div >
  );
}

export default App;