import { dentalOfficeName } from "../../Utils/AppDetails/appDetails";
import { encrypt, generateHMAC } from "../../Utils/hmac";
import { API_HOST } from "../BaseUrl";
import client, { headers } from "./config";

export const getAppsDetails = async (appUrl) => {
  const hmac = await generateHMAC()
  const updatedHeaders = {
    ...headers,
    "X-Rt": hmac.timestamp,
    "X-Hmac": hmac.token
  }
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.post(
        API_HOST + `apps/details`,
        { appUrl },
        { headers: updatedHeaders }
      );
      if (response.status === 200) {
        resolve(response);
      }
      reject(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const getBinaryDataOfClinicImage = (imageName) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
    hosts: window.location.origin,
  };

  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.get(API_HOST + `binaryDataOfClinicImage/${imageName}`, { headers });
      if (response.status === 200) {
        resolve(response);
      }
      reject(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const checkAppVersion = () => {
  const updatedHeaders = {
    ...headers,
    Authorization: localStorage.getItem("authToken"),
  };

  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.get(API_HOST + `update-hygiene-version`, {
        headers: updatedHeaders,
      });
      if (response.status === 200) {
        resolve(response);
      }
      reject(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const getTpScanAppDetails = async ({ c }) => {
  const hmac = await generateHMAC()
  const updatedHeaders = {
    ...headers,
    "X-Rt": hmac.timestamp,
    "X-Hmac": hmac.token
  }
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.get(API_HOST + `tp-app/${c}`, { headers: updatedHeaders });
      if (response.status === 200) {
        resolve(response);
      }
      reject(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const postLogDetails = async (data) => {
  const hmac = await generateHMAC()
  const _n = encrypt(data)
  const updatedHeaders = {
    ...headers,
    "X-Rt": hmac.timestamp,
    "X-Hmac": hmac.token
  }
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.post(API_HOST + `ping`, { _n }, { headers: updatedHeaders });
      if (response.status === 200) {
        resolve(response);
      }
      reject(response);
    } catch (error) {
      reject(error);
    }
  });
};