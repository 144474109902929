import react, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import successImage from "../../assets/onboarding-images/success.png";
import { useDispatch, useSelector } from "react-redux";
import { createFullScanS3Folder } from "../../Store/fullScan";
import stars from "../../assets/illustrations/backgorund-stars.png";
import teeth from "../../assets/illustrations/thanksTeeth.png";
import toothlensLogo from "../../assets/illustrations/Toothlens_logo.png";
import { companyBucket } from "../../Utils/tpscanStyle";
import doneDownload from "../../assets/bernImages/doneDownload.jpg";
import close from "../../assets/Icons/close.png"
import { downloadTpScanPdf } from "../../Services/APIs/scan";
const SuccessScreen = ({ isFullScan }) => {
  const { dentalOfficeName } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { fullScanS3Folder } = useSelector((state) => state.fullScan);

  // useEffect(() => {
  //   if (
  //     localStorage.getItem("usertpi") &&
  //     JSON.parse(localStorage.getItem("company_style_details")).load_bearn_flow
  //   ) {
  //     navigate(`/tpscan/bearn`);
  //   }
  // }, []);

  const startScan = () => {
    !fullScanS3Folder && dispatch(createFullScanS3Folder());
    navigate(`/${dentalOfficeName}/full-scan`);
  };

  const [DownloadStatus, setDownloadStatus] = useState("Download My Report");
  const download = async () => {
    setDownloadStatus("Downloading...");
    const file = localStorage.getItem("pdf");
    if (file) {
      const bucketName = JSON.parse(localStorage.getItem("companyBucket")).companyBucket;
      await downloadTpScanPdf({ file, company: bucketName, uid: JSON.parse(localStorage.getItem("usertpi")).usertpi })
        .then((response) => {
          const url = response.data?.data?.info;
          const link = document.createElement("a");
          link.href = url;
          link.download = file; // Optional: Specify a download attribute
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setDownloadStatus("Downloaded");
          setTimeout(() => {
            setDownloadStatus("Download My Report");
          }, 2500);
        })
        .catch((error) => {
          setDownloadStatus("Failed, try again...");
          setTimeout(() => {
            setDownloadStatus("Download My Report");
          }, 2500);
        });
    } else {
      setDownloadStatus("Not Found");
    }
  };

  const TpSuccess = () => {
    return (
      <div>
        {companyBucket == "done" ? (
          <div>
            <div className="h-screen bg-custom-gradient text-center flex flex-col justify-center items-center px-3">
              <div className="absolute top-0 right-0 p-3">
                <a
                  href="https://app.dentistry.one/"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img src={close} alt="close_img" width="26px" />
                </a>
              </div>
              <h1
                style={{
                  color: "#172433",
                  textAlign: "center",
                  fontFamilyt: "IvyMode",
                  fontWeight: "700",
                  fontSize: "24px",
                }}
              >
                Preparing your report
              </h1>
              <div className="mt-5 flex justify-center">
                <img src={doneDownload} alt="done" />
              </div>
              <div className="mt-5 flex justify-center">
                <div
                  style={{
                    color: "#2C3E50",
                    fontFamily: "Poppins",
                    fontWeight: "700",
                    fontSize: "16px",
                  }}
                >
                  Your report will be ready shortly.
                </div>
              </div>
              <div
                className="flex justify-center "
                style={{
                  color: "#2C3E50",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                }}
              >
                We will notify you via email or SMS once your report is
                prepared.
              </div>
            </div>
          </div>
        ) : (
          <div className="h-screen text-center flex flex-col justify-center items-center px-3">
            <img src={stars} alt="stars" className="absolute top-0 w-full" />
            <img src={teeth} alt="teeth" className=" h-40  saturate-50 mb-6" />
            <p className=" font-medium text-2xl mb-3">Thank you!</p>
            <p className=" text-sm opacity-60 px-5">
              Your scan is complete. Keep smiling and see you at your next scan!
            </p>
            {localStorage.getItem("actualCompany") &&
              <div className="mt-8  w-full px-5 pb-3  bg-gray-300/[0.1] backdrop-blur-xs rounded ">
                {DownloadStatus === "Downloaded" ? (
                  <p className="py-3 bg-green-100 rounded text-center font-semibold text-green-900">
                    Downloaded successfully
                  </p>
                ) : (
                  <button
                    type="button"
                    className="bg-black text-white w-full h-11 font-semibold text-base rounded shadow-2xl"

                    onClick={() => {
                      download();
                    }}
                    style={{ border: "1px solid #575EE4" }}
                  >
                    {DownloadStatus}
                  </button>
                )}
              </div>}
            <p className="flex flex-row justify-center absolute bottom-8">
              <span className="opacity-60"> Powered by </span>
              <img
                src={toothlensLogo}
                alt="welcome teeth"
                className="h-6 ml-2"
              />
            </p>
          </div>
        )}
      </div>
    );
  };

  const AppSuccess = () => {
    return (
      <div className="h-full w-full z-30 bg-white absolute top-0">
        <div className="h-3/4 relative bg-orange-100">
          <img
            src={successImage}
            alt="success"
            className="w-full h-full object-cover"
          />
          <div className="absolute bottom-0 h-2/5 w-full bg-gradient-to-t from-white"></div>
        </div>
        <div className="w-full absolute bottom-4 px-5">
          <h1 className=" text-26 font-medium text-left leading-8">
            Perfect shot! well done
          </h1>
          <p className="text-base text-left mt-2">
            {isFullScan
              ? "Your scan report is ready"
              : "You’re now ready to take your scan!"}
          </p>
          <button
            type="button"
            className="bg-black text-white w-full h-11 mb-1 mt-5 font-semibold text-base shadow-2xl"
            onClick={() => {
              isFullScan
                ? navigate(`/${dentalOfficeName}/dashboard`)
                : startScan();
            }}
          >
            {isFullScan ? "Check Report" : "Start Scanning"}
          </button>
          {!isFullScan && (
            <button
              type="button"
              className=" bg-white text-black font-normal text-base w-full h-11 border border-black  mb-4 mt-2"
              onClick={() => {
                navigate(`/${dentalOfficeName}/dashboard`);
              }}
            >
              Skip
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <>{localStorage.getItem("usertpi") ? <TpSuccess /> : <AppSuccess />}</>
  );
};

export default SuccessScreen;
