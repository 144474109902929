import axios from "axios";
import { API_HOST } from "../BaseUrl"
import client, { headers } from "./config"

// please note : there is a difference between axios and client imported instances

export const refreshToken = async (token, email) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios.post(API_HOST + `user/refresh-token`, { token, email }, { headers })
            if (response.status === 200) {
                const { jwtToken, ...user } = response.data.data
                localStorage.setItem('user', JSON.stringify(user))
                localStorage.setItem('authToken', jwtToken)
                resolve(response)
            }
            reject(response)

        } catch (error) {
            logoutService()
            reject(error)
        }
    })

}

export const LoginService = async (request) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios.post(API_HOST + `user/login`, request, { headers })
            if (response.status === 200) {
                resolve(response)
            }
            reject(response)
        } catch (error) {
            reject(error)
        }

    })

}

export const logoutService = () => {
    const token = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).token : ''
    const updatedHeaders = { ...headers, Authorization: localStorage.getItem("authToken") }

    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios.put(API_HOST + `user/logout`, { token }, { headers: updatedHeaders })
            if (response.status === 200) {
                localStorage.clear()
                resolve(response)
            }
            localStorage.clear()
            reject(response)

        } catch (error) {
            localStorage.clear()
            reject(error)
        }
    })
}

export const registerUser = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios.post(API_HOST + `user/register`, data, { headers })
            if (response.status === 200) {
                resolve(response)
            }
            reject(response)

        }
        catch (error) {
            reject(error)
        }
    })
}

export const ValidateOtp = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios.post(API_HOST + `user/emailverify`, data, { headers })
            if (response.status === 200) {
                resolve(response)
            }
            reject(response)

        } catch (error) {
            reject(error)
        }
    })
}

export const forgotPassword = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios.post(API_HOST + `user/forgotpassword`, data, { headers })
            if (response.status === 200) {
                resolve(response)
            }
            reject(response)

        } catch (error) {
            reject(error)
        }
    })
}

export const resetPassword = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios.post(API_HOST + `user/resetpassword`, data, { headers })
            if (response.status === 200) {
                resolve(response)
            }
            reject(response)

        } catch (error) {
            reject(error)
        }
    })
}

export const changePassword = (data) => {
    const updatedHeaders = { ...headers, Authorization: localStorage.getItem("authToken") }

    return new Promise(async (resolve, reject) => {
        try {
            const response = await client.post(API_HOST + `user/changePassword`, data, { headers: updatedHeaders })
            if (response.status === 200) {
                resolve(response)
            }
            reject(response)

        } catch (error) {
            reject(error)
        }
    })
}

