import React, { useEffect, useState } from "react";
import QRCode from "qrcode";
import logo from "../../assets/illustrations/Toothlens Logo.jpeg";
import {
  getAppsDetails,
  getBinaryDataOfClinicImage,
} from "../../Services/APIs/appDetails";
import { dentalOfficeName } from "../../Utils/AppDetails/appDetails";

const QrCode = () => {
  const [qrCode, setQrcode] = useState("");
  const [img, setImg] = useState([]);
  const [loading, setLoading] = useState(true);
  const companyBucket = window.location.pathname.split("/")[2];

  const getInitialAppDetails = async () => {
    await getAppsDetails(dentalOfficeName)
      .then((resData) => {
        if (resData.data.data !== "No Data") {
          localStorage.setItem("appDetails", JSON.stringify(resData.data.data));
          getBinaryDataOfClinicImage(resData.data.data.app_logo_file)
            .then((res) => {
              if (res.status == 200) {
                const byteArray = new Uint8Array(res.data.data.binaryData.data); // Convert the array of integers to a byte array
                const blob = new Blob([byteArray], { type: "image/jpeg" }); // Assuming it's a JPEG image
                const imageUrl = URL.createObjectURL(blob);
                localStorage.setItem("imageblob", JSON.stringify(res.data.data.binaryData.data));
                setImg([imageUrl]);
                setLoading(false);
              }
            })
            .catch(err => setLoading(false));
        }
      })
      .catch((err) => setLoading(false));
  };

  useEffect(() => {
    QRCode.toDataURL(window.location.href).then((data) => setQrcode(data))
    getInitialAppDetails();
  }, []);


  return (
    <div className="h-full w-full flex justify-center">
      <div className="h-full w-full min-w-96 max-w-xl bg-slate-100">
        <div className=" bg-black flex justify-center items-center py-3">
          <div className="bg-white w-24 h-24 rounded-full flex justify-center items-center">
            <img
              // src={loading ? logo : img[0]}
              src={logo}
              className="object-content h-12"
              alt="logo"
            />
          </div>
          <p className="text-white font-semibold text-xl ms-4">TOOTHLENS</p>
        </div>
        <div>
          <h1 className=" text-xl font-semibold text-center mt-10">
            THANKS FOR VISITING
          </h1>
          <p className="text-center mt-3 leading-6">
            Toothlens works on mobile devices only. Please scan to use the App
            <br />
            or type
            <span className="font-bold"> {window.location.href} </span> in
            your mobile browser.
          </p>
        </div>
        <div className="mt-8">
          {qrCode && (
            <img
              className=" h-72 mx-auto shadow-xl"
              src={qrCode}
              alt="Qr code"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default QrCode;
