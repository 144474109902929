import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import welcomeTeeth from "../../assets/illustrations/welcomeTeeth.png";
import "../../App.css";
import { getAppsDetails, getBinaryDataOfClinicImage } from "../../Services/APIs/appDetails";
import { dentalOfficeName } from "../../Utils/AppDetails/appDetails";
import toothlensLogo from "../../assets/illustrations/Toothlens_logo.png"
import GoogleLogin from "../Authentication/Login/gooleLogin";

const LandingPage = () => {
  const navigate = useNavigate();
  const [img, setImg] = useState([])
  const [loading, setLoading] = useState(true)
  const [isError, setAppError] = useState(false)


  const getInitialDetails = async () => {
    setLoading(true)
    localStorage.removeItem("appDetails")
    localStorage.removeItem("dentalOffice")
    await getAppsDetails(dentalOfficeName).then(
      (resData) => {
        if (resData.data.data !== "No Data") {
          localStorage.setItem(
            "appDetails",
            JSON.stringify(resData.data.data)
          );
          localStorage.setItem("dentalOffice", dentalOfficeName)
          setAppError(false)
          getBinaryDataOfClinicImage(resData.data.data.app_logo_file)
            .then((res) => {
              if (res.status == 200) {
                const byteArray = new Uint8Array(
                  res.data.data.binaryData.data
                ); // Convert the array of integers to a byte array
                const blob = new Blob([byteArray], { type: "image/jpeg" }); // Assuming it's a JPEG image
                const imageUrl = URL.createObjectURL(blob);
                localStorage.setItem('imageblob', JSON.stringify(res.data.data.binaryData.data));
                setImg([imageUrl])
                setLoading(false)
              }
            })
            .catch((err) => {
              setLoading(false)
            });
        }
      },
    ).catch((err) => {
      setLoading(false)
      setAppError(true)
    })
  }
  useEffect(() => {
    getInitialDetails()
  }, []);
  return (
    <div className="h-full">
      <div className="h-1/3  flex flex-col items-center justify-center ">
        <img
          src={welcomeTeeth} //img[0]
          alt="welcome teeth"
          className="px-4 h-36 w-full object-contain"
        />
      </div>
      <div className="h-2/3 relative">
        <h1 className=" text-2xl font-normal text-center mb-5">Welcome to toothlens</h1>
        <p className="font-medium text-center text-black opacity-60">Instant Dental Health Scan</p>
        <p className="font-medium text-center text-black opacity-60 mb-4">Snap a photo for AI-powered insights</p>
        <div className=" py-4 flex flex-col items-center px-4">
          {
            loading ?
              <div className="loader-container mt-5">
                <div className="loader"></div>
              </div> : isError ?
                <>
                  <p className="text-red-600">App details not found</p>
                  <button type="button"
                    className="bg-black text-white py-2 px-8 rounded mt-2"
                    onClick={() => { window.location.reload() }} > Retry </button>
                </> :
                <>

                  <button
                    type="button"
                    className="bg-black text-white w-full h-11 font-semibold text-base mb-5 shadow-2xl "
                    onClick={() => {
                      navigate(`/${dentalOfficeName}/login`);
                    }}
                  >
                    Login
                  </button>
                  <button
                    type="button"
                    className=" bg-white text-black font-normal text-base w-full h-11 border border-black "
                    onClick={() => {
                      navigate(`/${dentalOfficeName}/sign-in`);
                    }}
                  >
                    Sign Up
                  </button>
                  <GoogleLogin />
                  <p className="text-center mt-4 font-normal text-sm text-black">
                    <span className="opacity-60"> Need help? Visit our </span>
                    <span className="font-bold pl-2">
                      <a href="mailto:support@toothlens.com"> Help Center </a>
                    </span>
                  </p>
                </>
          }
        </div>
        <div className="absolute bottom-0 w-full">


          <p className="flex my-3 flex-row justify-center">
            <span className="opacity-60 italic"> Powered by  </span>
            <img src={toothlensLogo} alt="welcome teeth" className="h-6 ml-2 w-24" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
