import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import welcomeTeeth from "../../assets/illustrations/welcomeTeeth.png";
import "../../App.css";
import toothlensLogo from "../../assets/illustrations/Toothlens_logo.png";
import { getTpStatus } from "../../Services/APIs/scan";
import { tpscanName } from "../../Utils/AppDetails/appDetails";
import { getTpScanAppDetails, postLogDetails } from "../../Services/APIs/appDetails";

const TpScanLanding = () => {
  const [error, setError] = useState();
  const navigate = useNavigate();
  const [uiLoading, setUiLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const { uid } = useParams();
  const companyBucket = window.location.pathname.split("/")[2];
  localStorage.setItem("companyBucket", JSON.stringify({ companyBucket: companyBucket }));
  localStorage.setItem("usertpi", JSON.stringify({ usertpi: uid }));

  const takeScan = () => {
    navigate(`/${tpscanName}/onboarding`);
  };

  const initialCall = async () => {
    await getTpScanAppDetails({ c: tpscanName })
      .then(async (res) => {
        setUiLoading(false);
        localStorage.setItem("company_style_details", JSON.stringify(res.data.data));
        await getTpStatus({ uid, c: tpscanName })
          .then((res) => {
            if (companyBucket == "done" && !res.data.data?.tpid) {
              navigate(`/${tpscanName}/onboarding`);
            }
            setLoading(false);
            setUiLoading(false);
          }).catch((err) => {
            setLoading(false);
            err.response.status === 410 ? setError(`The report with ${uid} is already used!`) : setError(`${err.response.data.message[0].msg}`)
          })

      })
      .catch((err) => {
        setLoading(false);
        setUiLoading(false);
        err.status === 404 ? setError("invalid route") : setError("Something went and wrong")
      });
  };

  const company_style_details = JSON.parse(
    localStorage.getItem("company_style_details")
  );
  const buttonStyle = {
    backgroundColor: company_style_details
      ? `${company_style_details.button_background_color}`
      : "#000000",
  };
  const fontWeight = {
    fontWeight: company_style_details
      ? `${company_style_details.onboarding_text_font_weight}`
      : "",
  };
  const fontStyle = {
    fontFamily: company_style_details
      ? `${company_style_details.onboarding_text_font_style}`
      : "Raleway",
  };

  useEffect(() => {
    localStorage.setItem("reload", true)
    initialCall();
    postLogDetails({
      uid: JSON.parse(localStorage.getItem("usertpi")).usertpi,
      app: JSON.parse(localStorage.getItem("companyBucket")).companyBucket,
      payLoad: `Visited landing page`
    })
  }, []);

  return (
    <div className="h-full">
      <div className="h-1/2">
        <img
          src={welcomeTeeth}
          alt="welcome teeth"
          className="saturation h-full w-full object-contain"
        />
      </div>
      {uiLoading ? (
        <div className="loader-container">
          <div className="loader dark-loader"></div>
        </div>
      ) : (
        <div className="h-1/2 relative flex flex-col justify-center">
          <h5
            style={{ ...fontWeight, ...fontStyle }}
            className=" text-2xl  font-medium text-center mb-3"
          >
            Instant Dental Health Scan
          </h5>
          <div
            style={{ ...fontStyle }}
            className="text-lg font-medium text-center text-black opacity-60 mb-3"
          >
            Snap a photo for
            <br /> AI-powered insights
          </div>

          <div>
            <div className="h-48  flex flex-col items-center px-4 py-2">
              {error ? (
                <h1 className="text-xl text-red-700  font-medium text-center">
                  {error}
                </h1>
              ) : (
                <button
                  style={{ ...buttonStyle }}
                  type="button"
                  className={`rounded  w-full h-11 font-semibold text-base mb-4  ${companyBucket == "toothlens" || "banningdental" ? "bg-black text-white" : "text-white"}`}
                  onClick={() => { takeScan() }}
                  disabled={loading}
                >
                  {loading ? (
                    <div className="loader-container">
                      <div className="loader dark-loader"></div>
                    </div>
                  ) : (
                    <span>Scan </span>
                  )}
                </button>
              )}
            </div>
          </div>

          <div className="absolute bottom-11 w-full px-4">
            <p className="flex flex-row justify-center">
              <span className="opacity-60"> Powered by </span>
              <img
                src={toothlensLogo}
                alt="welcome teeth"
                className="h-6 ml-2 w-24"
              />
            </p>
          </div>

        </div>
      )}
    </div>
  );
};

export default TpScanLanding;
